<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('seeds_report.overallDisburseReport') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
            <b-row>
              <b-col xs="12" sm="12" md="4" lg="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="org_id"
                      >
                      <template v-slot:label>
                          {{ $t('org_pro.organization')}} <span class="text-danger"></span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          id="org_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="fiscal_year_id"
                  >
                  <template v-slot:label>
                      {{$t('warehouse_config.fiscal_year')}} <span class="text-danger"></span>
                  </template>
                  <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalList"
                      id="fiscal_year_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
               <b-col xs="12" sm="12" md="4" lg="4">
                <ValidationProvider name="Measurement" vid="measurement_unit_id">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="measurement_unit_id"
                  >
                  <template v-slot:label>
                    {{$t('seedsSeeds.unitName')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="search.measurement_unit_id"
                    :options="unitNameList"
                    id="measurement_unit_id"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="production_season_id"
                  >
                  <template v-slot:label>
                      {{$t('seedsConfig.productionSeason')}} <span class="text-danger"></span>
                  </template>
                  <b-form-select
                      plain
                      v-model="search.production_season_id"
                      :options="productionSeasonList"
                      id="production_season_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="crop_type"
                  >
                  <template v-slot:label>
                      {{$t('seedsConfig.cropType')}} <span class="text-danger"></span>
                  </template>
                  <b-form-select
                      plain
                      v-model="search.crop_type_id"
                      :options="cropTypeList"
                      id="crop_type_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="crop_name_id"
                >
                <template v-slot:label>
                    {{$t('seedsConfig.cropName')}} <span class="text-danger"></span>
                </template>
                <b-form-select
                    plain
                    v-model="search.crop_name_id"
                    :options="cropNameList"
                    id="crop_name_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="variety"
                  >
                  <template v-slot:label>
                      {{$t('seedsSeeds.variety')}} <span class="text-danger"></span>
                  </template>
                  <b-form-select
                      plain
                      v-model="search.variety_id"
                      :options="VarietyList"
                      id="crop_name_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
               <b-col xs="12" sm="12" md="4" lg="4">
              <ValidationProvider name="from_date" vid="from_date">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('globalTrans.from_date')"
                label-for="email"
                >
                <template v-slot:label>
                {{$t('globalTrans.from_date')}}
                </template>
                <flat-pickr class="form-control"
                v-model="search.from_date"
                placeholder="Select Date"
                ></flat-pickr>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4">
              <ValidationProvider name="to_date" vid="to_date">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-cols-lg="4"
                label-for="to_date"
                >
                <template v-slot:label>
                {{$t('globalTrans.to_date')}}
                </template>
                <flat-pickr class="form-control"
                v-model="search.to_date"
                placeholder="Select Date"
                ></flat-pickr>
                </b-form-group>
              </ValidationProvider>
            </b-col>
              <b-col xs="12" sm="12" md="4" lg="4">
              <b-col xs="12" sm="12" md="4" lg="4">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.submit')}}</b-button>
              </b-col>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('seeds_report.overallDisburseReport') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <export-excel
                            class="btn btn_add_new"
                            :data="excelData"
                            :title="$t('seeds_report.overallDisburseReport')"
                            worksheet="Overal Disburse Report"
                            name="filename.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id" :key="search.org_id">
                                  {{ $t('seeds_report.overallDisburseReport')}}
                                </list-report-head>
                              </b-col>
                              </b-row>
                              <b-col>
                                <div class="text-center">
                                    <table style="width:100%;color:black;">
                                        <tr v-if="search.fiscal_year">
                                            <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                        </tr>
                                        <tr v-if="search.measurement_unit">
                                            <td align="right" style="width:45%">{{ $t('seedsSeeds.unitName') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.measurement_unit_bn : search.measurement_unit }}</td>
                                        </tr>
                                        <tr v-if="search.season_name">
                                            <td align="right" style="width:45%">{{ $t('seedsConfig.productionSeason') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.season_name_bn : search.season_name }}</td>
                                        </tr>
                                         <tr v-if="search.crop_type_name">
                                            <td align="right" style="width:45%">{{ $t('seedsConfig.cropType') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.crop_type_name_bn : search.crop_type_name }}</td>
                                        </tr>
                                         <tr v-if="search.crop_name">
                                            <td align="right" style="width:45%">{{ $t('seedsConfig.cropName') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.crop_name_bn : search.crop_name }}</td>
                                        </tr>
                                        <tr v-if="search.variety_name">
                                            <td align="right" style="width:45%">{{ $t('seedsConfig.variety') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.variety_name_bn : search.variety_name }}</td>
                                        </tr>
                                        <tr v-if="search.from_date">
                                            <td align="right" style="width:45%">{{ $t('globalTrans.from_date') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{  search.from_date | dateFormat }}</td>
                                        </tr>
                                        <tr v-if="search.to_date">
                                            <td align="right" style="width:45%">{{ $t('globalTrans.to_date') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:50%">{{  search.to_date | dateFormat }}</td>
                                        </tr>
                                    </table>
                                </div>
                              </b-col>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                 <b-table bordered hover :items="stockList" :fields="columns" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                  <template v-slot:cell(index)="data">
                                    {{ $n((data.index+1)) }}
                                  </template>
                                  <template v-slot:cell(item_quantity)="data">
                                    {{ $n(data.item.item_quantity, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(crop_type_id)="data">
                                    <span class="capitalize">{{ getDataById(data.item.crop_type_id, 'cropType') }}</span>
                                  </template>
                                  <template v-slot:cell(crop_name_id)="data">
                                    <span class="capitalize">{{ getDataById(data.item.crop_name_id, 'cropName') }}</span>
                                  </template>
                                  <template v-slot:cell(variety_id)="data">
                                    <span class="capitalize">{{ getDataById(data.item.variety_id, 'Variety') }}</span>
                                  </template>
                                  <template v-slot:cell(disburses_quantity)="data">
                                    {{ $n(data.item.disburses_quantity, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(status)="data">
                                    {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                                  </template>
                                </b-table>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, overallDisburseReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadSeed.vue'
import ExportPdf from '@/Utils/export-pdf'
import { dateFormat } from '@/Utils/fliter'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      deliveryScheduleList: [],
      search: {
        org_id: 0,
        production_season_id: 0,
        measurement_unit_id: 2,
        crop_type_id: 0,
        crop_name_id: 0,
        variety_id: 0,
        fiscal_year_id: 0,
        from_date: 0,
        to_date: 0,
        to_sales_office_id: this.$store.state.dataFilters.officeId,
        from_spc_office_id: this.$store.state.dataFilters.officeId
      },
      cropNameList: [],
      excelData: [],
      currentDate: '',
      stockList: [],
      VarietyList: [],
      testId: 0,
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('seedsConfig.cropType'), class: 'text-left' },
          { label: this.$t('seedsConfig.cropName'), class: 'text-left' },
          { label: this.$t('seedsConfig.variety'), class: 'text-left' },
          { label: this.$t('seeds_report.disburse_quantity'), class: 'text-left' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'crop_type_id' },
          { key: 'crop_name_id' },
          { key: 'variety_id' },
          { key: 'disburses_quantity' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'crop_type_id' },
          { key: 'crop_name_id' },
          { key: 'variety_id' },
          { key: 'disburses_quantity' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
        })
    },
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    currentFiscalYearId: function () {
      return this.$store.state.SeedsFertilizer.currentFiscalYearId
    },
    unitNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.unitList
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.crop_type_id': function (newVal, oldVal) {
      if (newVal) {
        this.cropNameList = this.getCropNameList(newVal)
      } else {
        this.cropNameList = []
      }
    },
    'search.crop_name_id': function (newVal, oldVal) {
      if (newVal) {
        this.VarietyList = this.getVarietyList(newVal)
      } else {
        this.VarietyList = []
      }
    }
  },
  created () {
    this.search.org_id = this.$store.state.dataFilters.orgId
    this.search = Object.assign({}, this.search, { fiscal_year_id: this.currentFiscalYearId })
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.organizationData()
      this.getCustomDataSearch()
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === this.search.org_id)

      RestApi.getData(seedFertilizerServiceBaseUrl, reportHeadingList + '/' + this.search.org_id, { org_id: this.search.org_id }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn,
            project_name: orgObject.project_name,
            project_name_bn: orgObject.project_name_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        }
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      await RestApi.getData(seedFertilizerServiceBaseUrl, overallDisburseReport, this.search).then(response => {
      if (response.success) {
        if (response.data) {
           this.dataList(response.data)
        }
      } else {
        this.stockList = []
      }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getCustomDataSearch () {
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.search.fiscal_year = fiscalYearObj.text_en
          this.search.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.search.fiscal_year = ''
          this.search.fiscal_year_bn = ''
        }
        const productionSeasonObj = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.find(item => item.value === this.search.production_season_id)
          this.search.season_name = typeof productionSeasonObj !== 'undefined' ? productionSeasonObj.text_en : ''
          this.search.season_name_bn = typeof productionSeasonObj !== 'undefined' ? productionSeasonObj.text_bn : ''
        const measurementUnitObj = this.$store.state.SeedsFertilizer.commonObj.unitList.find(unit => unit.value === this.search.measurement_unit_id)
          this.search.measurement_unit = typeof measurementUnitObj !== 'undefined' ? measurementUnitObj.text_en : ''
          this.search.measurement_unit_bn = typeof measurementUnitObj !== 'undefined' ? measurementUnitObj.text_bn : ''
        const cropTypeObj = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(item => item.value === this.search.crop_type_id)
          this.search.crop_type_name = typeof cropTypeObj !== 'undefined' ? cropTypeObj.text_en : ''
          this.search.crop_type_name_bn = typeof cropTypeObj !== 'undefined' ? cropTypeObj.text_bn : ''

        const cropNameObj = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(item => item.value === this.search.crop_name_id)
          this.search.crop_name = typeof cropNameObj !== 'undefined' ? cropNameObj.text_en : ''
          this.search.crop_name_bn = typeof cropNameObj !== 'undefined' ? cropNameObj.text_bn : ''

        const varietyNameObj = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(item => item.value === this.search.variety_id)
          this.search.variety_name = typeof varietyNameObj !== 'undefined' ? varietyNameObj.text_en : ''
          this.search.variety_name_bn = typeof varietyNameObj !== 'undefined' ? varietyNameObj.text_bn : ''
    },
    dataList (data) {
    var myArray = data.data
      var groups = {}
      for (var i = 0; i < myArray.length; i++) {
        var cropType = myArray[i].crop_type_id
        if (!groups[cropType]) {
            groups[cropType] = []
        }
        var newData = {
          crop_type_id: myArray[i].crop_type_id,
          crop_name_id: myArray[i].crop_name_id,
          variety_id: myArray[i].variety_id,
          disburses_quantity: myArray[i].disburses_quantity
        }
        groups[cropType].push(newData)
      }
      myArray = []
      for (var Name in groups) {
        myArray.push({ crop_type_id: Name, data: groups[Name] })
      }
      const newarr = myArray
      var customarray = newarr
      customarray.forEach(item => {
        item.data.forEach((listItem, index) => {
          if (index === 0) {
              listItem.crop_type_id = item.crop_type_id
          } else {
               listItem.crop_type_id = null
          }
        })
      })
    const pdf = []
      customarray.forEach((item) => {
        item.data.forEach((list) => {
          pdf.push(list)
        })
      })
    var myArray1 = pdf
      var groups1 = {}
      for (var j = 0; j < myArray1.length; j++) {
        var cropName = myArray1[j].crop_name_id
        if (!groups1[cropName]) {
            groups1[cropName] = []
        }
        var newData1 = {
          crop_type_id: myArray1[j].crop_type_id,
          crop_name_id: myArray1[j].crop_name_id,
          variety_id: myArray1[j].variety_id,
          disburses_quantity: myArray1[j].disburses_quantity
        }
        groups1[cropName].push(newData1)
      }
      myArray1 = []
      for (var Name1 in groups1) {
        myArray1.push({ crop_name_id: Name1, data: groups1[Name1] })
      }
      const newarr1 = myArray1
      var customarray1 = newarr1
      customarray1.forEach(item => {
        item.data.forEach((listItem, index) => {
          if (index === 0) {
              listItem.crop_name_id = item.crop_name_id
          } else {
               listItem.crop_name_id = null
          }
        })
      })
    const pdf1 = []
      customarray1.forEach((item) => {
        item.data.forEach((list) => {
          pdf1.push(list)
        })
      })
    this.stockList = pdf1
    var serial = 0
    const rowData = data.data.map((keyItem, index) => {
         serial += 1
         if (this.$i18n.locale === 'en') {
             return {
                'sl ': this.$n(serial),
                'Crop Type': this.getDataById(this.stockList[index].crop_type_id, 'cropType'),
                'Crop Name': this.getDataById(this.stockList[index].crop_name_id, 'cropName'),
                'Variety ': this.getDataById(this.stockList[index].variety_id, 'Variety'),
                'Total Quanty': this.$n(keyItem.disburses_quantity)
            }
         } else {
             return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'শস্যের ধরণ': this.getDataById(this.stockList[index].crop_type_id, 'cropType'),
                'শস্যের নাম': this.getDataById(this.stockList[index].crop_name_id, 'cropName'),
                'ভ্যারাইটি ': this.getDataById(this.stockList[index].variety_id, 'Variety'),
                'মোট পরিমাণ ': this.$n(keyItem.disburses_quantity)
            }
         }
     })
    this.excelData = rowData
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const fromDate = this.$i18n.locale === 'en' ? this.search.from_date : dateFormat(this.search.from_date)
      const toDate = this.$i18n.locale === 'en' ? this.search.to_date : dateFormat(this.search.to_date)
      const fiscalYear = this.$i18n.locale === 'en' ? this.search.fiscal_year : this.search.fiscal_year_bn
      const measurementUnit = this.$i18n.locale === 'en' ? this.search.measurement_unit : this.search.measurement_unit_bn
      const productionSeason = this.$i18n.locale === 'en' ? this.search.season_name : this.search.season_name_bn
      const cropType = this.$i18n.locale === 'en' ? this.search.crop_type_name : this.search.crop_type_name_bn
      const cropName = this.$i18n.locale === 'en' ? this.search.crop_name : this.search.crop_name_bn
      const variety = this.$i18n.locale === 'en' ? this.search.variety_name : this.search.variety_name_bn
      const header = [
        fromDate ? { text: this.$t('globalTrans.from_date') + ' : ' + fromDate, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        toDate ? { text: this.$t('globalTrans.to_date') + ' : ' + toDate, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        fiscalYear ? { text: this.$t('warehouse_config.fiscal_year') + ' : ' + fiscalYear, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        measurementUnit ? { text: this.$t('seedsSeeds.unitName') + ' : ' + measurementUnit, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        productionSeason ? { text: this.$t('seedsConfig.productionSeason') + ' : ' + productionSeason, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        cropType ? { text: this.$t('seedsConfig.cropType') + ' : ' + cropType, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        cropName ? { text: this.$t('seedsConfig.cropName') + ' : ' + cropName, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : '',
        variety ? { text: this.$t('seedsConfig.variety') + ' : ' + variety, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) } : ''
      ]

      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('seedsConfig.cropType'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('seedsConfig.cropName'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('seedsConfig.variety'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('seeds_report.disburse_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header]
      }
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Overall Disburse  Report' : 'সামগ্রিক বিতরণ প্রতিবেদন'
      const columnWids = ['20%', '20%', '20%', '20%', '20%']
      ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids, extraData)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_id' : 'crop_type_id' },
          { key: this.$i18n.locale === 'en' ? 'crop_name_id' : 'crop_name_id' },
          { key: this.$i18n.locale === 'en' ? 'variety_id' : 'variety_id' },
          { key: this.$i18n.locale === 'en' ? 'disburses_quantity' : 'disburses_quantity' }
        ]
      var serial = 0
      const listData = this.stockList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'crop_type_id') {
                return { text: this.getDataById(item[keyItem.key], 'cropType') }
            }
            if (keyItem.key === 'crop_name_id') {
                return { text: this.getDataById(item[keyItem.key], 'cropName') }
            }
            if (keyItem.key === 'variety_id') {
                return { text: this.getDataById(item[keyItem.key], 'Variety') }
            }
            if (keyItem.key === 'disburses_quantity') {
                return { text: this.$n(item[keyItem.key]) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    getVarietyList (cropID) {
      return this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.crop_name_id === cropID)
    },
    getDataById (Id, type) {
        if (type === 'cropType') {
            if (Id) {
               const tmpCropType = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(doc => doc.value === parseInt(Id))
               if (tmpCropType) {
                   return this.$i18n.locale === 'bn' ? tmpCropType.text_bn : tmpCropType.text_en
               } else {
                   return null
               }
            } else {
                return null
            }
        }

        if (type === 'cropName') {
            if (Id) {
               const tmpCropName = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(doc => doc.value === parseInt(Id))
                if (tmpCropName) {
                   return this.$i18n.locale === 'bn' ? tmpCropName.text_bn : tmpCropName.text_en
               } else {
                   return null
               }
            } else {
                return null
            }
        }

        if (type === 'Variety') {
            if (Id) {
               const tmpVariety = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(doc => doc.value === parseInt(Id))
                if (tmpVariety) {
                   return this.$i18n.locale === 'bn' ? tmpVariety.text_bn : tmpVariety.text_en
               } else {
                   return null
               }
            } else {
                return null
            }
        }
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
