// product stock report api
export const reportHeadingList = '/seeds/config/report-heading/detail'
export const productStockReport = '/seeds/stock-manage/sd-stock/report'

// Office Wise Seed Allocation Report
export const organizationWiseSeedAllocationReport = 'seeds/reports/seeds-allocation/organization-wise-list'

// overall stock report
export const overallStockReport = 'seeds/reports/seeds-stock/overall-seeds-stock-list'
export const dealerWiseSeedAllocationReport = 'seeds/reports/seeds-allocation/dealer-wise-list'

// overall stock report
export const overallDisburseReport = 'seeds/reports/seeds-disburse/overall-seeds-disburse-list'

// office wise stock Report
export const officeWiseStockReport = 'seeds/reports/office-wise-stock-report/office-stock-report'

// Office Wise Sales Stock Report
export const officeWiseSalesReport = 'seeds/reports/office-wise-sales-report/office-sales-report'
